'use strict'

var riot = require('riot')
var Hammer = require('hammerjs')

function go (direction) {
  // Search for link in pager.
  var link = document.querySelector('pager .' + direction + ' a')

  // Redirect to route if pager link was found.
  if (link) {
    riot.route(link.getAttribute('href').substr(1))
  }
}

function navigate (e) {
  // Arrow left / right navigation.
  if (e.keyCode === 37) {
    go('prev')
  } else if (e.keyCode === 39) {
    go('next')
  }
}

function swipe () {
  var hammertime = new Hammer(document.body, {
    inputClass: Hammer.TouchInput,
    cssProps: {
      userSelect: true
    }
  })

  hammertime.on('swipeleft', function () {
    go('next')
  })

  hammertime.on('swiperight', function () {
    go('prev')
  })
}

module.exports = {
  go: go,
  navigate: navigate,
  swipe: swipe
}
