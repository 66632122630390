/* global RiotControl */
'use strict'

function getYears () {
  var years = []
  var currentYear = 2020
  var i

  for (i = 2010; i <= currentYear; i++) {
    years.push(i)
  }

  years.reverse()

  return years
}

function getMonthsInfo () {
  var currentMonth = new Date().getMonth() + 1
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  return {
    months: months,
    current: currentMonth
  }
}

function navigate (e) {
  var dataList = this.parent
  var month = parseInt(this.monthSelect.value, 10)
  var year = parseInt(this.yearSelect.value, 10)
  var filterFocused = [this.monthSelect, this.yearSelect].indexOf(e.target) !== -1
  var add

  var focusMonth = function () {
    if (filterFocused) {
      this.monthSelect.focus()
    }
  }

  var focusYear = function () {
    if (filterFocused) {
      this.yearSelect.focus()
    }
  }

  var keys = {
    // arrow up
    38: 'prev',
    // arrow down
    40: 'next',
    // arrow left
    37: focusMonth,
    // m
    77: function () {
      if (document.activeElement !== this.monthSelect) {
        this.monthSelect.focus()
      } else {
        return true
      }
    },
    // arrow right
    39: focusYear,
    // y
    89: function () {
      if (document.activeElement !== this.yearSelect) {
        this.yearSelect.focus()
      } else {
        return true
      }
    }
  }
  var key = keys[e.keyCode]

  if (!key || e.metaKey || e.ctrlKey) {
    return true
  }

  // execute key function
  if (typeof key === 'function') {
    if (typeof key.call(this) === 'undefined') {
      e.preventDefault()
    }

    return true
  }

  if (!filterFocused) {
    return true
  }

  e.preventDefault()

  add = key === 'prev' ? -1 : 1

  // manipulate filter values
  if (e.target === this.monthSelect) {
    month += add

    // reset month when reaching start or end
    if (month > 12) {
      month = 1
    } else if (month < 1) {
      month = 12
    }
  } else {
    year += add

    // reset year when reaching end
    if (year > this.years[0]) {
      year = this.years[this.years.length - 1]
    } else if (year < this.years[this.years.length - 1]) {
      year = this.years[0]
    }
  }

  dataList.trigger('filter:changed', {
    month: month,
    year: year
  })
}

module.exports = {
  getYears: getYears,
  getMonthsInfo: getMonthsInfo,
  navigate: navigate
}
