var riot = require('riot');
module.exports = 
riot.tag2('artist-filter', '<input type="search" name="search" placeholder="Search" autocomplete="off" oninput="{onInput}">', '', '', function(opts) {
    var self = this

    self.on('update', function () {

      var focus = typeof window.ontouchstart === 'undefined'

      if (focus) {
        this.search.focus()
      }
    })

    this.onInput = function(e) {
      var dataList = this.parent;
      dataList.trigger('filter:changed', e.target)
    }.bind(this)
});