var riot = require('riot');
module.exports = 
riot.tag2('nav', '<ul> <li><a href="/" class="{active: currentPage == &quot;month-list&quot; || currentPage == &quot;event-list&quot;}">Event</a></li> <li><a href="/artists" class="{active: currentPage == &quot;artist-list&quot;}">DJ</a></li> </ul>', '', 'class="{hidden: isHidden}"', function(opts) {
    var riot = require('riot')
    var self = this

    self.isHidden = false

    var redirectHome = function () {
      riot.route('/')
    }

    var redirectArtists = function () {
      riot.route('/artists')
    }

    var keydownListener = function (e) {
      var keys = {
        37: {
          condition: !self.isHidden,
          fn: redirectHome
        },
        72: {
          fn: redirectHome
        },
        39: {
          condition: !self.isHidden,
          fn: redirectArtists
        }
      }

      var key = keys[e.keyCode]
      var tagBlacklist = ['input', 'select']
      var inputField = tagBlacklist.indexOf(e.target.tagName.toLowerCase()) !== -1

      if (!key || (typeof key.condition !== 'undefined' && !key.condition) || inputField) {
        return
      }

      key.fn.call()
    }

    RiotControl.on('route:change', function (currentPage) {
      var pages = ['month-list', 'event-list', 'artist-list']
      var isHidden = pages.indexOf(currentPage) === -1

      self.update({
        isHidden: isHidden,
        currentPage: currentPage
      })
    })

    self.on('mount', function () {
      document.addEventListener('keydown', keydownListener, false)
    })

    self.on('unmount', function () {
      document.removeEventListener('keydown', keydownListener, false)
    })
});