/* global RiotControl */
'use strict'

function onSearchInput (change) {
  var value = change.value

  RiotControl.trigger('artists:filter', value)
}

function navigate (e, tag, childTagName) {
  var childTag = tag.tags[childTagName]
  var search = tag.tags['artist-filter'].search
  var items = childTag.length ? childTag : [childTag]
  var totalItems = items.length
  var activeItem
  var activeIndex
  var key

  var up = {
    name: 'up',
    initial: 0
  }

  var down = {
    name: 'down',
    initial: -1
  }

  var keys = {
    // arrow up
    38: up,
    // k
    75: up,
    // arrow down
    40: down,
    // j
    74: down
  }

  // Ignore j and k keys when search is focused.
  // This allows the user to move the cursor when entering some text.
  if (document.activeElement === search) {
    delete keys[74]
    delete keys[75]
  }

  key = keys[e.keyCode]

  if (!key || e.metaKey || e.ctrlKey) {
    return true
  }

  e.preventDefault()

  activeItem = items.find(function (item) {
    return item.active || item.upcoming
  })

  activeIndex = activeItem ? items.indexOf(activeItem) : key.initial

  if (activeItem) {
    // Remove active/upcoming state.
    items[activeIndex].active = false
    items[activeIndex].update()
  }

  if (key.name === 'up') {
    activeIndex = activeIndex - 1
  } else if (key.name === 'down') {
    activeIndex = activeIndex + 1
  }

  if (activeIndex > totalItems - 1) {
    activeIndex = -1
  } else if (activeIndex < 0) {
    // Next active item is last item if search is focused.
    activeIndex = document.activeElement === search ? totalItems - 1 : -1
  }

  if (activeIndex > -1) {
    items[activeIndex].active = true
    items[activeIndex].focus = true

    items[activeIndex].one('updated', function () {
      this.link.focus()
    })

    items[activeIndex].update()
  } else {
    search.focus()
  }
}

module.exports = {
  onSearchInput: onSearchInput,
  navigate: navigate
}
