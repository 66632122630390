'use strict'

module.exports = {
  artists: {
    tag: 'artist-list'
  },
  artist: {
    tag: 'artist',
    params: ['slug']
  },
  imprint: {
    tag: 'imprint'
  },
  'privacy-policy': {
    tag: 'privacy-policy'
  },
  'app-privacy-policy': {
    tag: 'privacy'
  },
  year: {
    tag: 'month-list',
    params: ['year']
  },
  events: {
    tag: 'event-list',
    params: ['month']
  },
  event: {
    tag: 'event',
    params: ['date', 'artist']
  }
}
