'use strict'

var riot = require('riot')
var request = require('superagent')
var moment = require('moment')
var appUrl = window.klubnacht.app
var apiUrl = window.klubnacht.api

var FULL_FORMAT = 'YYYY-MM-DD'
var DISPLAY_FORMAT_SHORT = 'DD MMM'

function EventStore () {
  var self = this

  riot.observable(this)

  self.cachedEvents = {}

  self.on('events:year', function (year) {
    var cacheId = year
    var url = [apiUrl, 'events', year].join('/')

    self.trigger('loading', true)

    if (self.cachedEvents[cacheId]) {
      // explicit async
      setTimeout(function () {
        self.trigger('loading', false)
        self.trigger('events:changed', self.cachedEvents[cacheId])
      }, 0)
    } else {
      request
        .get(url)
        .end(function (err, res) {
          var eventDates
          var byClub
          var event

          if (err) {
            throw err
          }

          byClub = JSON.parse(res.text)

          if (!byClub.length) {
            return self.trigger('events:changed', [])
          }

          // omit club, merge event name & overwrites, just take eventDates
          event = byClub[0].events[0]
          eventDates = event.eventDates

          eventDates = eventDates.map(function (eventDate) {
            eventDate.title = event.event_name
            eventDate.special = false

            Object.keys(eventDate.overwrites || {}).forEach(function (key) {
              var value = eventDate.overwrites[key]
              eventDate[key] = value
            })

            // Check whether event date is a special one.
            if (eventDate.overwrites && typeof eventDate.overwrites.title !== 'undefined') {
              eventDate.special = true
              eventDate.title = eventDate.overwrites.title
            }

            delete eventDate.overwrites

            // Keep link to original page.
            eventDate.link = eventDate.url
            eventDate.url = [appUrl, moment(eventDate.date).format(FULL_FORMAT)].join('/')

            return eventDate
          })

          self.cachedEvents[cacheId] = eventDates
          self.trigger('loading', false)
          self.trigger('events:changed', eventDates)
        })
    }
  })

  self.on('events:month', function (change) {
    var month = parseInt(change.month, 10)
    var cacheId = [change.year, change.month].join('-')
    var url = [apiUrl, 'events', change.year, month].join('/')

    self.trigger('loading', true)

    if (self.cachedEvents[cacheId]) {
      // explicit async
      setTimeout(function () {
        self.trigger('loading', false)
        self.trigger('events:changed', self.cachedEvents[cacheId])
      }, 0)
    } else {
      request
        .get(url)
        .end(function (err, res) {
          var eventDates,
            byClub,
            event

          if (err) {
            location.href = '/error.html'
            // throw err
          }

          byClub = JSON.parse(res.text)

          if (!byClub.length) {
            return self.trigger('events:changed', [])
          }

          // omit club, merge event name & overwrites, just take eventDates
          event = byClub[0].events[0]
          eventDates = event.eventDates

          eventDates = eventDates.map(function (eventDate) {
            eventDate.title = event.event_name
            eventDate.special = false

            Object.keys(eventDate.overwrites || {}).forEach(function (key) {
              var value = eventDate.overwrites[key]
              eventDate[key] = value
            })

            // Check whether event date is a special one.
            if (eventDate.overwrites && typeof eventDate.overwrites.title !== 'undefined') {
              eventDate.special = true
              eventDate.title = eventDate.overwrites.title
            }

            delete eventDate.overwrites

            // Keep link to original page.
            eventDate.link = eventDate.url
            eventDate.url = [appUrl, moment(eventDate.date).format(FULL_FORMAT)].join('/')

            return eventDate
          })

          self.cachedEvents[cacheId] = eventDates
          self.trigger('loading', false)
          self.trigger('events:changed', eventDates)
        })
    }
  })

  self.on('event:date', function (date, artist) {
    var cacheId = date
    var cachedEventDate = self.cachedEvents[cacheId]
    var dateMoment = moment(date)
    var url = [apiUrl, 'event_by_date', date].join('/')
    var backgroundUrl = [apiUrl, 'flyer', dateMoment.year(), dateMoment.month() + 1].join('/')
    var pagerType = 'default'

    if (artist) {
      url += '?artist=' + artist
      pagerType = artist
    }

    self.trigger('loading', true)

    if (cachedEventDate && !artist) {
      setTimeout(function () {
        self.trigger('loading', false)
        self.trigger('event:view', cachedEventDate)
      })
    } else {
      request
        .get(url)
        .end(function (err, res) {
          var eventDate

          if (err) {
            throw err
          }

          eventDate = JSON.parse(res.text)

          // Insert artist pager into cached event date.
          if (cachedEventDate && artist && Object.keys(cachedEventDate.pager).indexOf(artist) !== -1) {
            cachedEventDate.pager[artist] = eventDate.pager[artist]
          } else {
            eventDate.floor.map(function (floor) {
              return floor.playtimes.map(function (playtime) {
                return playtime.artists.map(function (artist) {
                  if (artist.slug === window.klubnacht.artist.unmapped) {
                    artist.disabled = true
                  }

                  return artist
                })
              })
            })

            eventDate.special = false

            // Check whether event date is a special one.
            if (eventDate.overwrites && typeof eventDate.overwrites.title !== 'undefined') {
              eventDate.special = true
              eventDate.event_name = eventDate.overwrites.title
            }

            // Setting URL to background image.
            eventDate.backgroundUrl = backgroundUrl
          }

          // Iterate pager items, add title and url property.
          Object.keys(eventDate.pager[pagerType]).map(function (key) {
            var page = eventDate.pager[pagerType][key]
            dateMoment = moment(page.date)

            page.title = dateMoment.format(DISPLAY_FORMAT_SHORT)
            page.url = '/' + dateMoment.format(FULL_FORMAT)

            if (artist) {
              page.url += '?artist=' + artist
            }

            delete page.date

            return page
          })

          self.cachedEvents[cacheId] = eventDate
          self.trigger('loading', false)
          self.trigger('event:view', eventDate)
        })
    }
  })
}

module.exports = EventStore
