var riot = require('riot');
module.exports = 
riot.tag2('countdown', '<span title="{explanation}">{hours}<span class="separator">:</span>{minutes}</span>', '', '', function(opts) {
    var padLeft = require('lodash.padleft')
    var moment = require('moment')
    var countdown = require('countdown')
    require('moment-countdown')
    var self = this

    self.on('update', function () {
      var timer = moment().countdown(self.opts.until, countdown.HOURS|countdown.MINUTES)

      self.explanation = timer.toString() + ' remaining'

      self.hours = timer.hours
      self.minutes = padLeft(timer.minutes, 2, 0)
    })
});