var riot = require('riot');
module.exports = 
riot.tag2('month-list', '<year-filter year="{year}" class="filter"></year-filter> <div class="months"> <month-item each="{index, month in months}" year="{year}" month="{index}" events="{month}"></month-item> </div>', '', '', function(opts) {
    var moment = require('moment')
    var yearFilter = require('./year-filter')
    var self = this

    self.defaultYear = moment().year()
    self.year = self.opts.year || self.defaultYear

    self.on('filter:changed', yearFilter.onSearchInput)

    self.on('mount', function () {
      RiotControl.one('events:changed', function () {
        self.trigger('ready')
      })
    })

    RiotControl.on('events:changed', function (events) {
      var year = parseInt(self.year)
      var pageTitle = ''

      if (!(year === self.defaultYear)) {
        pageTitle = moment()
          .year(year)
          .format('YYYY')
          .toUpperCase()
      }

      self.months = events.reduce(function(result, eventDate) {
        var month = moment(eventDate.date).month()
        result[month] = result[month] || []

        result[month].push(eventDate)

        return result
      }, {})

      RiotControl.trigger('page:title', pageTitle)
      RiotControl.trigger('track:page')

      self.update()
    })

    self.on('router:update', function (opts) {
      if (!opts) {
        opts = {
          year: self.defaultYear
        }
      }

      self.year = opts.year

      RiotControl.trigger('events:year', year)
    })
});