'use strict'

function noop (arg) {
  return arg
}

function formatPlural (number, singular, plural) {
  if (number === 1) {
    return number + ' ' + singular
  }

  return number + ' ' + plural
}

function formatDays (hours) {
  return Math.round(hours / 24 * 10) / 10
}

function playtime (type, playtime) {
  if (type === 'hours') {
    return formatPlural(playtime, 'hour', 'hours')
  } else if (type === 'days') {
    return formatDays(playtime) + ' days'
  }
}

function filterEventDates (eventDates, filter) {
  return eventDates.filter(function (eventDate) {
    return eventDate.playtimes.some(function (playtime) {
      return (filter.live && playtime.live) || (filter.set && !playtime.live) || (filter.closing && playtime.closing)
    })
  })
}

function filterChange (e) {
  var self = this
  var filter = e.target.value
  var checked = e.target.checked
  var filtersDisabled
  var filterFn

  var filterKeys = Object.keys(self.filter)
  var activatedFilters = filterKeys.filter(function (filterKey) {
    return filterKey === filter ? checked : self.filter[filterKey]
  })

  //  Check whether we need  to filter event dates or not.
  filtersDisabled = !activatedFilters.length
  filterFn = filtersDisabled ? noop : filterEventDates

  // Setting filter states.
  filterKeys.map(function (filterName) {
    self.filter[filterName] = filterName === filter && checked
  })

  self.playtimeSwitch = self.defaults.playtimeSwitch
  self.playtimeMode = self.defaults.playtimeMode
  self.eventDates = filterFn(self.artist.eventDates, self.filter)

  self.update()
}

function navigate (e, tag, childTagName) {
  var childTag = tag.tags[childTagName]
  var items = childTag.length ? childTag : [childTag]
  var totalItems = items.length
  var activeItem
  var activeIndex

  var up = {
    name: 'up',
    initial: 0
  }

  var down = {
    name: 'down',
    initial: -1
  }

  var keys = {
    // arrow up
    38: up,
    // k
    75: up,
    // arrow down
    40: down,
    // j
    74: down
  }

  var key = keys[e.keyCode]

  if (!key || e.metaKey || e.ctrlKey) {
    return true
  }

  e.preventDefault()

  activeItem = items.find(function (item) {
    return item.active || item.upcoming
  })

  activeIndex = activeItem ? items.indexOf(activeItem) : key.initial

  if (activeItem) {
    // Remove active/upcoming state.
    items[activeIndex].active = false
    items[activeIndex].upcoming = false
    items[activeIndex].update()
  }

  if (key.name === 'up') {
    activeIndex = activeIndex - 1
  } else if (key.name === 'down') {
    activeIndex = activeIndex + 1
  }

  if (activeIndex > totalItems - 1) {
    activeIndex = 0
  } else if (activeIndex < 0) {
    activeIndex = totalItems - 1
  }

  items[activeIndex].active = true
  items[activeIndex].focus = true

  items[activeIndex].one('updated', function () {
    this.link.focus()
  })

  items[activeIndex].update()
}

module.exports = {
  filterChange: filterChange,
  playtime: playtime,
  navigate: navigate
}
