var riot = require('riot');
module.exports = 
riot.tag2('event-filter', '<div> <select name="monthSelect" onchange="{onMonthSelect}"> <option each="{month, i in months}" value="{i + 1}" __selected="{currentMonth - 1 == i}">{month}</option> </select> <select name="yearSelect" onchange="{onYearSelect}"> <option each="{year in years}" value="{year}" __selected="{currentYear == year}">{year}</option> </select> </div>', '', '', function(opts) {
    var eventFilter = require('./event-filter')
    var self = this
    var monthInfo = eventFilter.getMonthsInfo()
    var keydownListener = eventFilter.navigate.bind(this)

    this.years = eventFilter.getYears()
    this.months = monthInfo.months

    if (this.opts.year && this.opts.month) {
      this.currentMonth = this.opts.month
      this.currentYear = this.opts.year
    }

    this.on('update', function (opts) {
      if (!opts) {
        return
      }

      this.currentMonth = opts.month
      this.currentYear = opts.year
    })

    this.on('mount', function () {
      document.addEventListener('keydown', keydownListener, false)

      this.parent.trigger('filter:changed', {
        month: this.currentMonth,
        year: this.currentYear
      }, true)
    })

    this.on('unmount', function () {

      document.removeEventListener('keydown', keydownListener, false)
    })

    this.onInput = function(e) {
      var dataList = this.parent;
      dataList.trigger('filter:changed', e.target)
    }.bind(this)

    this.onMonthSelect = function(e) {
      var month = e.target.value
      var year = this.yearSelect.value

      var dataList = this.parent;
      dataList.trigger('filter:changed', {
        month: month,
        year: year
      })
    }.bind(this)

    this.onYearSelect = function(e) {
      var year = e.target.value
      var month = this.monthSelect.value

      var dataList = this.parent;
      dataList.trigger('filter:changed', {
        month: month,
        year: year
      })
    }.bind(this)
});