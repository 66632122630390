var riot = require('riot');
module.exports = 
riot.tag2('artist', '<pager items="{artist.pager}"></pager> <h1><a href="{artist.url}" target="_blank">{artist.name}</a></h1> <ul if="{artist.aliases.length}" class="aliases"> <li each="{artist.aliases}"><a href="{this.url}">{this.name}</a></li> </ul> <div if="{(artist.playtimes.live &amp;&amp; artist.playtimes.set) || artist.playtimes.closing}" class="filter {dirty: dirtyFilters}"><span if="{artist.playtimes.set}"> <label for="filter-set" title="{filter.set ? &quot;Hide sets&quot; : &quot;Show sets&quot;}"> <input type="checkbox" id="filter-set" value="set" onchange="{filterChange}" __checked="{filter.set}"><span>Set</span> </label></span><span if="{artist.playtimes.closing}"> <label for="filter-closing" title="{filter.closing ? &quot;Hide closings&quot; : &quot;Show closings&quot;}"> <input type="checkbox" id="filter-closing" value="closing" onchange="{filterChange}" __checked="{filter.closing}"><span>Closing</span> </label></span><span if="{artist.playtimes.live}"> <label for="filter-live" title="{filter.live ? &quot;Hide live sets&quot; : &quot;Show live sets&quot;}"> <input type="checkbox" id="filter-live" value="live" onchange="{filterChange}" __checked="{filter.live}"><span>Live</span> </label></span></div> <h2 onclick="{togglePlaytime}" class="{clickable: playtimeSwitch}"><span if="{eventDates.length &gt; 1}">{eventDates.length} results</span><span if="{playtime}" class="playtime">{totalPlaytime}</span></h2> <div class="list"> <ul> <li each="{eventDates}"> <event-item content="{this}" full="true" artist="{artist.slug}"></event-item> </li> </ul> </div>', '', '', function(opts) {
    var artistUtils = require('./artist')
    var self = this
    self.filterChange = artistUtils.filterChange

    self.defaults = {
      playtimeSwitch: false,
      playtimeMode: 'hours'
    }
    self.playtimeSwitch = self.defaults.playtimeSwitch
    self.playtimeMode = self.defaults.playtimeMode
    self.filter = {
      set: false,
      closing: false,
      live: false
    }

    self.togglePlaytime = function () {
      if (!self.playtime || !self.playtimeSwitch) {
        return
      }

      self.playtimeMode = self.playtimeMode === 'hours' ? 'days' : 'hours'
      self.totalPlaytime = artistUtils.playtime(self.playtimeMode, self.playtime)
    }

    function requestArtist(params) {
      var slug = (params || self.opts).slug

      RiotControl.trigger('artist:get', slug)
    }

    var keydownListener = function (e) {
      return artistUtils.navigate(e, self, 'event-item')
    }

    self.on('mount', requestArtist)
    self.on('router:update', requestArtist)

    self.on('mount', function () {
      document.addEventListener('keydown', keydownListener, false)
    })

    self.on('update', function () {
      var playtime = 0;
      var filterKeys = Object.keys(self.filter)
      var activatedFilters;

      if (!self.artist) {
        return;
      }

      activatedFilters = filterKeys.filter(function (filterKey) {
        return self.filter[filterKey]
      });

      if (!activatedFilters.length) {
        activatedFilters = filterKeys;
      }

      activatedFilters.forEach(function (filter) {
        if (self.artist.playtimes[filter]) {
          playtime += parseInt(self.artist.playtimes[filter] / 60, 10)
        }
      })

      self.playtime = playtime
      self.playtimeSwitch = self.playtime > 24
      self.totalPlaytime = artistUtils.playtime(self.playtimeMode, self.playtime)
    })

    RiotControl.one('artist:view', function () {
      self.trigger('ready')
    })

    RiotControl.on('artist:view', function (artist) {
      RiotControl.trigger('page:title', artist.name)
      RiotControl.trigger('track:page')

      self.eventDates = artist.eventDates
      self.artist = artist

      self.artist.url = [
        'http://www.residentadvisor.net/search.aspx?searchstr=',
        encodeURIComponent(artist.name),
        '&section=djs'
      ].join('')

      self.playtimeSwitch = self.defaults.playtimeSwitch
      self.playtimeMode = self.defaults.playtimeMode

      self.update()
    })

    self.on('unmount', function () {
      document.removeEventListener('keydown', keydownListener, false)

      RiotControl.off('artist:view')
    })
});