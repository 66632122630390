var riot = require('riot');
module.exports = 
riot.tag2('year-filter', '<div> <select name="yearSelect" onchange="{onYearSelect}"> <option each="{year in years}" value="{year}" __selected="{currentYear == year}">{year}</option> </select> </div>', '', '', function(opts) {
    var filter = require('../event-list/event-filter')
    var self = this

    this.years = filter.getYears()

    if (this.opts.year) {
      this.currentYear = this.opts.year
    }

    this.on('update', function (opts) {
      if (!opts) {
        return
      }

      this.currentYear = opts.year
    })

    this.on('mount', function () {
      this.parent.trigger('filter:changed', this.currentYear, true)
    })

    this.onInput = function(e) {
      var dataList = this.parent;
      dataList.trigger('filter:changed', e.target)
    }.bind(this)

    this.onYearSelect = function(e) {
      var year = e.target.value

      var dataList = this.parent;
      dataList.trigger('filter:changed', year)
    }.bind(this)
});