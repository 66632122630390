/* global RiotControl */
'use strict'

var moment = require('moment')

function formatDate (date) {
  return moment(date).format('DD MMM YY')
}

function formatPlaytime (date, index) {
  date = moment(date)

  // Avoids displaying of fake timestamps
  if (date.get('year') < 1980) {
    return ''
  }

  if (index === 0 && date.hour() === 0 && date.minute() === 0) {
    return '23:59'
  }

  return date.format('HH:mm')
}

function isActivePlaytime (playtime) {
  var now = moment()
  var start = moment(playtime.start)
  var end = moment(playtime.end)

  return now.isAfter(start) && now.isBefore(end)
}

function isDisabledPlaytime (playtimes, key) {
  var now = moment()
  var end = moment(playtimes[key].end)
  var last = moment(playtimes[playtimes.length - 1].end)

  return now.isAfter(end) && now.isBefore(last)
}

function ending (playtime) {
  var end = moment(playtime.end)
  var countdown = end.countdown()
  var hideCountdown = !(countdown.hours || countdown.minutes)

  return isActivePlaytime(playtime) && hideCountdown
}

function backgroundImage (url) {
  return 'background-image: url(' + url + ')'
}

module.exports = {
  formatDate: formatDate,
  playtime: {
    format: formatPlaytime,
    isActive: isActivePlaytime,
    isDisabled: isDisabledPlaytime,
    ending: ending
  },
  backgroundImage: backgroundImage
}
