var riot = require('riot');
module.exports = 
riot.tag2('event-item', '<a itemprop="url" href="{opts.content.url + this.urlSuffix}" name="link"><span class="visible">{formattedDate}</span><span if="{opts.content.special}" class="hidden">{title}</span></a> <meta itemprop="name" content="{title}"> <meta itemprop="sameAs" content="{originalUrl}"> <meta itemprop="startDate" content="{date}"> <meta itemprop="image" content="{image}"><span itemprop="location" itemscope="itemscope" itemtype="http://schema.org/Place"> <meta itemprop="name" content="Berghain"> <meta itemprop="sameAs" content="http://berghain.de"><span itemprop="address" itemscope="itemscope" itemtype="http://schema.org/PostalAddress"> <meta itemprop="name" content="Berghain"> <meta itemprop="streetAddress" content="Am Wriezener Bahnhof"> <meta itemprop="postalCode" content="10243"> <meta itemprop="addressLocality" content="Berlin"> <meta itemprop="addressCountry" content="Germany"></span></span>', '', 'itemscope="true" itemtype="http://schema.org/DanceEvent" class="{active: active, highlighted: opts.content.special, upcoming: upcoming}"', function(opts) {
    var moment = require('moment')

    function formatDate(date, short) {
      var format = short ? 'DD MMM' : 'DD MMM YY'

      return date.format(format).toUpperCase()
    }

    this.on('update', function (params) {
      var now = moment()
      var rawDate = this.opts.content.date
      var date = moment(rawDate)

      this.active = params.active || this.opts.content.active
      this.urlSuffix = opts.artist ? '?artist=' + opts.artist : ''
      this.upcoming = !this.active && this.opts.content.upcoming
      this.date = rawDate
      this.formattedDate = formatDate(date, !this.opts.full)
      this.title = opts.content.special ? opts.content.title.toUpperCase() : formatDate(date, false)
      this.image = this.opts.content.image
      this.originalUrl = this.opts.content.link
    })
});