var riot = require('riot');
module.exports = 
riot.tag2('klubnacht', '<div class="alert hidden"><a href="https://apps.apple.com/app/klubnacht/id1133164456" target="_blank">Current events are only available on our app</a></div> <header> <logo></logo> </header> <nav name="navigation"></nav> <content></content> <footer> <p if="{link.url}">Data is fetched from <a href="{link.url}">{link.text}</a> website ♥</p> <ul> <li><a href="/imprint">Imprint</a></li> <li><a href="/privacy-policy">Privacy policy</a></li> <li><a href="&#x6d;&#x61;&#x69;&#108;&#x74;&#111;&#58;&#115;&#x75;&#112;&#112;&#x6f;&#114;&#116;&#x40;&#107;&#x6c;&#x75;&#98;&#x6e;&#97;&#x63;&#x68;&#116;&#x2e;&#105;&#110;&#102;&#111;">Contact</a></li> </ul> </footer>', '', 'class="{loading: loading}"', function(opts) {
    var FastClick = require('fastclick').FastClick
    var Shake = require('shake.js')
    var location = window.location;
    var self = this
    var shake = new Shake()

    require('array.prototype.findindex')

    require('viewport-units-buggyfill').init()

    self.link = {
      url: 'http://berghain.de',
      text: 'Berghain'
    };

    document.addEventListener('keydown', function (e) {
      if (e.keyCode === 27) {
        document.activeElement.blur()
      }
    })

    var orientationChangeListener = function (e) {
      var multiplier = 200 / window.innerHeight * 3
      var prop = 'webkitTransform' in document.body.style ? 'webkitTransform' : transform
      document.body.style[prop] = 'rotateY(' + (e.gamma * multiplier * -1) + 'deg)'
    }

    var shakeListener = function () {
      var footer = document.querySelector('footer p')
      footer.outerHTML = footer.outerHTML.replace('♥', '🐎')

      document.documentElement.classList.add('skew')

      ga('send', 'event', 'App', 'Skew started')

      window.addEventListener('deviceorientation', orientationChangeListener)
      window.removeEventListener(shakeListener)
    }

    FastClick.attach(document.body)

    shake.start()

    window.addEventListener('shake', shakeListener)

    RiotControl.on('loading', function (loading) {
      self.update({ loading: loading })
    })

    RiotControl.on('page:title', function (title) {
      var seperator = title ? ' – ' : ''
      document.title = title + seperator + 'KLUBNACHT'
    })

    RiotControl.on('footer:update', function (link) {
      if (link === 'disabled') {
        self.link = {}
        self.update()

        return
      }

      self.link = link ? link : {
        url: 'http://berghain.de',
        text: 'Berghain'
      }

      self.update()
    })

    RiotControl.on('track:page', function () {
      if (window.ga) {
        window.ga('send', 'pageview', location.pathname, document.title);
      }
    })
});