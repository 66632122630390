var riot = require('riot');
module.exports = 
riot.tag2('month-item', '<h2><a href="{monthUrl()}">{formatMonth()}</a></h2> <div class="events"><a each="{event in opts.events}" href="{event.url}" title="{event.special ? event.title : &quot;&quot;}" class="{special: event.special}">{formatDate(event.date)}</a></div>', '', '', function(opts) {
    var padLeft = require('lodash.padleft')
    var moment = require('moment')

    this.formatMonth = function () {
      return moment().month(parseInt(opts.month, 10)).format('MMMM')
    }

    this.formatDate = function (date) {
      return moment(date).format('DD')
    }

    this.monthUrl = function () {
      month = padLeft(parseInt(opts.month, 10) + 1, 2, 0)

      return '/' + opts.year + '-' + month
    }
});