/*  eslint-disable no-unused-expressions */

'use strict'

var riot = require('riot')
var qs = require('qs')

var currentPage
var currentRiotTag

var HOME_PAGE = {
  tag: 'event-list'
}

var routes = require('./routes')

function transformParams (list, pattern) {
  var params = {}
  var paramName

  if (!pattern) {
    return false
  }

  // explicitly clone pattern
  pattern = [].concat(pattern)

  list.forEach(function (el) {
    if (pattern && pattern.length) {
      paramName = pattern.shift()
      params[paramName] = el
    } else {
      params.args = params.args || []
      params.args.push(el)
    }
  })

  return params
}

// path: alias/param1/param2/...
// route: { tag: 'tag-name', params: ['id'] }
// -> ['tag-name', { id: param1, args: [ 'param2', '...' ] }]
function parser (path) {
  var raw = path.split('?')
  var uri = raw[0].split('/')
  var alias = uri[0]
  var params = uri.slice(1)

  var parsedDate,
    tagName,
    routeInfo,
    queryParams

  // Short URL for eventDates: /YYYY-MM-DD
  parsedDate = parseDate(alias)
  if (parsedDate) {
    if (parsedDate.length === 1) {
      tagName = 'month-list'
      routeInfo = routes['month-list']
    } else if (parsedDate.length === 2) {
      tagName = 'event-list'
      routeInfo = routes.events
    } else {
      tagName = 'event'
      routeInfo = routes.event
    }

    params = {
      date: parsedDate.join('-'),
      month: parsedDate[1],
      year: parsedDate[0]
    }
  } else {
    routeInfo = routes[alias] || HOME_PAGE
    tagName = routeInfo.tag
    params = transformParams(params, routeInfo.params)
  }

  // Query parameters
  queryParams = qs.parse(raw[1])
  Object.keys(queryParams).forEach(function (param) {
    if (!params[param] && routeInfo.params && routeInfo.params.indexOf(param) !== -1) {
      params[param] = queryParams[param]
    }
  })

  return [tagName, params]
}

function router (page, params) {
  var content

  var currentPageTag
  var pageTag
  var newRiotTag

  var update = currentPage === page

  if (update) {
    return currentRiotTag.trigger('router:update', params)
  }

  RiotControl.trigger('loading', true)

  pageTag = document.createElement(page)
  newRiotTag = riot.mount(pageTag, page, params)[0]

  newRiotTag.one('ready', function () {
    content = document.querySelector('klubnacht content')
    currentPageTag = content.querySelector(currentPage || 'root')
    currentPage && currentRiotTag.unmount(true)

    content.replaceChild(pageTag, currentPageTag)

    currentPage = page
    currentRiotTag = newRiotTag

    // Reset scroll position on page change.
    window.scrollTo(0, 0)
    // Removes focus from current focused element.
    document.activeElement.blur()

    RiotControl.trigger('loading', false)

    RiotControl.trigger('route:change', currentPage)
  })
}

function parseDate (date) {
  var parsed = date.match(/^([0-9]{4})(?:-(0[1-9]|1[0-2])(?:-(0[1-9]|[1-2][0-9]|3[0-1]))?)?/)

  if (!parsed) {
    return false
  }

  parsed = parsed.slice(1).filter(function (token) {
    return !!token
  })

  return parsed
}

module.exports = {
  router: router,
  parser: parser
}
