var riot = require('riot');
module.exports = 
riot.tag2('artist-list', '<artist-filter class="filter"></artist-filter> <ul> <li each="{artists}"> <artist-item content="{this}"></artist-item> </li> </ul>', '', 'class="list"', function(opts) {
    var artistList = require('./artist-list');
    var self = this

    self.artists = opts.artists

    var keydownListener = function (e) {
      return artistList.navigate(e, self, 'artist-item')
    }

    RiotControl.trigger('page:title', 'Artists')

    self.on('mount', function () {
      document.addEventListener('keydown', keydownListener, false)

      RiotControl.trigger('artists:get');
    })

    self.on('filter:changed', artistList.onSearchInput)

    this.on('unmount', function () {
      document.removeEventListener('keydown', keydownListener, false)
    })

    RiotControl.one('artists:changed', function () {
      self.trigger('ready')
    })

    RiotControl.on('artists:changed', function (artistsList) {
      self.update({ artists: artistsList })
    })
});