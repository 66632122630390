var riot = require('riot')

var router = require('./router')

// Export RiotControl as global
RiotControl = require('riotcontrol')

require('./tags/klubnacht.tag')
require('./tags/logo.tag')
require('./tags/nav.tag')
require('./tags/content.tag')

require('./tags/events/month-list/month-list.tag')
require('./tags/events/month-list/month-item.tag')
require('./tags/events/month-list/year-filter.tag')
require('./tags/events/event-list/event-list.tag')
require('./tags/events/event-list/event-filter.tag')
require('./tags/events/event-item/event-item.tag')
require('./tags/events/event/event.tag')

require('./tags/artists/artist/artist.tag')
require('./tags/artists/artist-list/artist-list.tag')
require('./tags/artists/artist-list/artist-filter.tag')
require('./tags/artists/artist-item/artist-item.tag')

require('./tags/static/imprint.tag')
require('./tags/static/privacy-policy.tag')

require('./tags/partials/countdown/countdown.tag')
require('./tags/partials/pager/pager.tag')

require('./stores')

window.onload = function () {
  // Export Klubnacht as global
  Klubnacht = riot.mount('klubnacht')[0]
  riot.route.base('/')
  riot.route.parser(router.parser)
  riot.route(router.router)
  riot.route.start(true)
}
