'use strict'

var riot = require('riot')
var moment = require('moment')
var request = require('superagent')
var slug = require('slug')
var apiUrl = window.klubnacht.api

function sortBy (list, by) {
  var sorter

  if (by === 'alphabetical') {
    // Array.prototype.sort() defaults to alphabetically
  }

  if (by === 'playtime') {
    // crazy logic for playtimes
    sorter = function () {
      return Math.random() < 0.5
    }
  }

  return list.sort(sorter)
}

function ArtistStore () {
  var self = this

  riot.observable(this)

  self.artists = []
  self.cachedArtists = {}
  self.cachedPlaytimes = {}

  self.trigger('loading', true)

  request
    .get([apiUrl, 'artists'].join('/'))
    .end(function (err, res) {
      var artists

      if (err) {
        window.location.href = '/error.html'

        return
      }

      artists = JSON.parse(res.text)
      self.artists = artists.map(function (artist) {
        artist.title = artist.name
        return artist
      })

      self.trigger('loading', false)
      self.trigger('artists:fetched')
    })

  self.on('artists:filter', function (term) {
    var artistsList

    artistsList = self.artists.filter(function (artist) {
      return artist.slug.toLowerCase().indexOf(slug(term).toLowerCase()) !== -1
    })

    self.trigger('artists:changed', sortBy(artistsList, 'alphabetical').slice(0, 100))
  })

  self.on('artists:get', function () {
    self.one('artists:fetched', function () {
      self.trigger('artists:changed', sortBy(self.artists, 'playtime').slice(0, 100))
    })

    if (self.artists.length) {
      // prevent breaking the event handlers
      // with explicit async
      setTimeout(function () {
        self.trigger('artists:fetched')
      }, 0)
    }
  })

  self.on('artist:get', function (artistSlug) {
    var cacheId = artistSlug
    var url = [apiUrl, 'artist', artistSlug].join('/')

    self.trigger('loading', true)

    if (self.cachedArtists[cacheId]) {
      setTimeout(function () {
        self.trigger('loading', false)
        self.trigger('artist:view', self.cachedArtists[cacheId])
      }, 0)
    } else {
      request
      .get(url)
      .end(function (err, res) {
        var artist

        if (err) {
          throw err
        }

        artist = JSON.parse(res.text)

        request
            .get([url, 'pager'].join('/'))
            .end(function (pErr, pRes) {
              if (pErr) {
                throw pErr
              }

              artist.pager = JSON.parse(pRes.text)

              request
              .get([url, 'event_dates'].join('/'))
              .end(function (edErr, edRes) {
                var eventDates
                var playtimes = {set: 0, live: 0}

                if (edErr) {
                  throw edErr
                }

                eventDates = JSON.parse(edRes.text)
                eventDates.reverse()

                eventDates = eventDates.map(function (eventDate) {
                  eventDate.special = false

                  eventDate.url = '/' + moment(eventDate.date).format('YYYY-MM-DD')

                  Object.keys(eventDate.overwrites || {}).forEach(function (key) {
                    var value = eventDate.overwrites[key]
                    eventDate[key] = value
                  })

                  // Check whether event date is a special one.
                  if (eventDate.overwrites && typeof eventDate.overwrites.title !== 'undefined') {
                    eventDate.special = true
                    eventDate.title = eventDate.overwrites.title
                  }

                  delete eventDate.overwrites

                  playtimes = eventDate.playtimes.reduce(function (total, playtime) {
                    var filterKey = playtime.live ? 'live' : 'set'
                    var start = moment(playtime.start)
                    var end = moment(playtime.end)
                    var duration = end.diff(start, 'minutes')

                    // Just indicating that closings are available.
                    if (playtime.closing) {
                      total.closing = 1
                    }

                    total[filterKey] = (total[filterKey] || 0) + duration

                    return total
                  }, playtimes)

                  return eventDate
                })

                artist.eventDates = eventDates
                artist.playtimes = playtimes

                self.cachedArtists[cacheId] = artist
                self.trigger('loading', false)
                self.trigger('artist:view', artist)
              })

              self.cachedArtists[cacheId] = artist
            })
      })
    }
  })
}

module.exports = ArtistStore
