/* global RiotControl */
'use strict'

var padLeft = require('lodash.padleft')

function onSearchInput (change, noRoute) {
  var riot = require('riot')
  var month
  var path

  if (noRoute) {
    RiotControl.trigger('events:month', change)
  } else {
    month = padLeft(parseInt(change.month, 10), 2, 0)
    path = change.year + '-' + month

    RiotControl.trigger('track:page')

    riot.route(path)
  }
}

function navigate (e, tag, childTagName) {
  var items
  var activeItem
  var activeIndex
  var totalItems

  var up = {
    name: 'up',
    initial: 0
  }

  var down = {
    name: 'down',
    initial: -1
  }

  var keys = {
    // arrow up
    38: up,
    // k
    75: up,
    // arrow down
    40: down,
    // j
    74: down
  }

  var key = keys[e.keyCode]
  var tagBlacklist = ['input', 'select']
  var inputField = tagBlacklist.indexOf(e.target.tagName.toLowerCase()) !== -1

  if (!key || inputField || e.metaKey || e.ctrlKey) {
    return
  }

  e.preventDefault()

  items = tag.tags[childTagName]
  totalItems = items.length

  activeItem = items.find(function (eventItem) {
    return eventItem.active || eventItem.upcoming
  })

  activeIndex = activeItem ? items.indexOf(activeItem) : key.initial

  if (activeItem) {
    // Remove active/upcoming state.
    items[activeIndex].active = false
    items[activeIndex].upcoming = false
    items[activeIndex].update()
  }

  if (key.name === 'up') {
    activeIndex = activeIndex - 1
  } else if (key.name === 'down') {
    activeIndex = activeIndex + 1
  }

  if (activeIndex > totalItems - 1) {
    activeIndex = 0
  } else if (activeIndex < 0) {
    activeIndex = totalItems - 1
  }

  items[activeIndex].active = true
  items[activeIndex].focus = true

  items[activeIndex].one('updated', function () {
    this.link.focus()
  })

  items[activeIndex].update()
}

module.exports = {
  onSearchInput: onSearchInput,
  navigate: navigate
}
