/* global RiotControl */
'use strict'

function onSearchInput (year) {
  var riot = require('riot')

  RiotControl.trigger('events:year', year)

  riot.route(year)
}

module.exports = {
  onSearchInput: onSearchInput
}
