var riot = require('riot');
module.exports = 
riot.tag2('event-list', '<event-filter month="{month}" year="{year}" class="filter"></event-filter> <ul> <li each="{event in events}"> <event-item content="{event}"></event-item> </li> </ul>', '', 'class="list"', function(opts) {
    var moment = require('moment');
    var eventList = require('./event-list')
    var eventFilter = require('./event-filter')
    var monthInfo = eventFilter.getMonthsInfo()
    var self = this

    self.defaultMonth = 3
    self.defaultYear = 2020

    self.events = self.opts.events
    self.month = self.opts.month || self.defaultMonth
    self.year = self.opts.year || self.defaultYear

    self.on('filter:changed', eventList.onSearchInput)

    var keydownListener = function (e) {
      return eventList.navigate(e, self, 'event-item')
    }

    self.on('mount', function () {
      document.addEventListener('keydown', keydownListener, false)

      RiotControl.one('events:changed', function () {
        self.trigger('ready')
      })
    })

    RiotControl.on('events:changed', function (events) {
      var year = parseInt(self.year)
      var month = parseInt(self.month)
      var pageTitle = ''

      if (!(year === self.defaultYear && month === self.defaultMonth)) {
        pageTitle = moment()
          .year(year)
          .month(month - 1)
          .format('MMM YYYY')
          .toUpperCase()
      }

      RiotControl.trigger('page:title', pageTitle)
      RiotControl.trigger('track:page')

      var now = moment()
      var activeIndex = events.findIndex(function (eventDate, i) {
        var date = moment(eventDate.date)
        var endDate = date.clone().day(8).hour(10)
        var duration = moment.duration(endDate.diff(date))

        var realistic = duration.asDays() < 4

        return date.isBefore(now) && now.isBefore(endDate) && realistic
      })

      if (activeIndex !== -1) {
        events[activeIndex].active = true
      }

      var upcomingIndex = events.findIndex(function (eventDate) {
        return moment(eventDate.date).isAfter()
      })

      if (activeIndex === -1 && upcomingIndex !== -1) {
        events[upcomingIndex].upcoming = true
      }

      self.update({
        events: events,
        month: self.month,
        year: self.year
      })
    })

    self.on('router:update', function (opts) {
      if (!opts) {
        opts = {
          month: this.defaultMonth,
          year: this.defaultYear
        }
      }

      self.month = opts.month
      self.year = opts.year

      RiotControl.trigger('events:month', opts)
    })

    self.on('unmount', function () {
      document.removeEventListener('keydown', keydownListener, false)
    })
});