var riot = require('riot');
module.exports = 
riot.tag2('event', '<pager items="{event.pager[pagerType]}"></pager> <h1 class="{highlighted: event.special}">{event.event_name}</h1> <p class="date">{formatDate(this.event.date)}</p> <div class="lineup"> <div each="{floor, i in event.floor}" class="floor"> <div if="{i === 0}" riot-style="{backgroundImage}" class="mood"></div> <h2>{floor.floor_name}</h2> <table if="{floor.playtimes.length}"> <tbody> <tr each="{playtime, i in floor.playtimes}" class="{active: active(playtime), disabled: played(floor.playtimes, i), ending: ending(playtime)}"> <td>{formatPlaytime(playtime.start, i)}</td> <td class="{multiple: playtime.artists.length &gt; 1}"><span each="{playtime.artists}"><a href="/artist/{this.slug}" if="{!disabled}">{this.name}</a><span if="{disabled}">{this.name}</span></span><span if="{playtime.live}" class="live"> LIVE</span> <countdown if="{active(playtime)}" until="{playtime.end}" class="remaining-time"></countdown> </td> </tr> </tbody> </table> <div if="{!floor.playtimes.length}" class="empty">TBA</div> </div> </div>', '', '', function(opts) {
    var eventUtils = require('./event')
    var self = this
    var date = self.opts.date

    this.formatDate = eventUtils.formatDate
    this.formatPlaytime = eventUtils.playtime.format
    this.formatArtists = eventUtils.formatArtists
    this.active = eventUtils.playtime.isActive
    this.played = eventUtils.playtime.isDisabled
    this.ending = eventUtils.playtime.ending

    this.event = this.opts.event

    function requestEvent(params) {
      var date = (params || self.opts).date
      var artist = (params || self.opts).artist

      self.pagerType = artist ? artist : 'default'

      RiotControl.trigger('event:date', date, artist)
    }

    this.on('mount', function () {
      this.refreshInterval = setInterval(this.update, 15000)
    })

    this.on('mount', requestEvent)

    this.on('router:update', requestEvent)

    RiotControl.one('event:view', function () {
      self.trigger('ready')
    })

    RiotControl.on('event:view', function (eventDate) {
      var special = eventDate.overwrites && eventDate.overwrites.title
      var date = eventUtils.formatDate(eventDate.date).toUpperCase()
      var title = special || date
      var link = {
        url: eventDate.url,
        text: eventDate.url.indexOf('berghain.de') !== -1 ? 'Berghain' : 'Resident Advisor'
      }

      self.backgroundImage = eventUtils.backgroundImage(eventDate.backgroundUrl)

      RiotControl.trigger('page:title', title)
      RiotControl.trigger('footer:update', link)
      RiotControl.trigger('track:page')

      self.update({ event: eventDate })
    })

    this.on('unmount', function () {
      clearInterval(this.refreshInterval)
      RiotControl.trigger('footer:update', '')
      RiotControl.off('event:view')
    })
});